<template lang="html">
  <div class="">
    <v-toolbar dense color="white" flat scrollable class="elevation-1 searchbar" dark>
      <v-layout   align-center>
        <div class="hidden-sm-and-down">
  <!-- Dates normal -->
        <v-menu :nudge-width="100" :close-on-content-click="false" ref="dateMenu" v-model="dateMenu" color="primary">
          <v-btn color="primary" :flat="!datesSet" slot="activator">
            <span v-if="!datesSet">{{$t('resultsPage.dates')}}</span>
            <span v-else>{{filters.mid | displayDate}} <span class="MRAIcon_arrow_dates"></span> {{filters.mod | displayDate}}</span>
            <v-icon>arrow_drop_down</v-icon>
          </v-btn>
          <v-card>
            <DatePicker @saveDate="saveDate" @cancel="dateMenu=false" :mid="mid" :mod="mod"></DatePicker>
          </v-card>
        </v-menu>
  <!-- Price normal -->
        <v-menu :nudge-width="100" :close-on-content-click="false" ref="priceMenu" v-model="priceMenu">
          <v-btn :flat="!priceSet" color="primary" slot="activator">
            <span>{{$t('resultsPage.budget')}}</span>
            <v-icon>arrow_drop_down</v-icon>
          </v-btn>
          <v-card class="pa-2">
            <v-card-title primary-title>
              {{$t('resultsPage.yourBudget')}}: {{price}}TWD
            </v-card-title>
            <v-card-text>
              <v-text-field
                prepend-icon="create"
                suffix="TW$"
                :value="price"
                @change="sendPrice"
                type="number"
              ></v-text-field>
              <v-slider hint="Select your budget" @end="sendPrice" v-model="price" :min="min" :max="max" :step="500" thumb-color="primary" thumb-label thumb-size="40" prepend-icon="attach_money">
              </v-slider>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
  <!-- Desktop Search expand -->


  <!-- MOBILE DIALOG -->
      <v-btn color="primary" :flat="!othersSet" @click="showAllFilters=!showAllFilters">
        <v-icon>filter_list</v-icon>
        <span class="pa-1">{{$t('resultsPage.filters')}}</span>
        <v-icon>arrow_drop_down</v-icon>
      </v-btn>

      <div class="">
        <v-dialog
          v-model="showAllFilters"
          scrollable
          persistent
          max-width="500px"
          transition="dialog-transition"

        >
        <!-- all filters -->
        <v-card>
          <v-card-title
            primary-title
            class="headline"
          >
            <v-icon>filter_list</v-icon><span>  {{$t('resultsPage.filters')}}</span>
            <v-spacer></v-spacer>
            <v-icon @click="showAllFilters=!showAllFilters">close</v-icon>
          </v-card-title>

          <v-card-text>
            <!-- Order by Mobile -->

            <v-layout  wrap>
              <v-flex xs12 class="title">
                {{$t('resultsPage.orderBy')}}
              </v-flex>
              <v-flex xs12>
                <v-select
                  :items="orderOptions"
                  v-model="orderBy"
                >
                </v-select>
              </v-flex>
            </v-layout>
        <!-- Dates mobile  -->
            <v-layout  wrap class="hidden-md-and-up">
              <v-flex xs12 class="title">
                {{$t('resultsPage.dates')}}
              </v-flex>
              <v-flex xs12>
                <v-menu :nudge-width="100" :close-on-content-click="false" ref="dateMenu2" v-model="dateMenu2" color="primary">
                  <v-btn color="primary" :flat="!datesSet" slot="activator">
                    <v-icon left>calendar_today</v-icon>
                    <span v-if="!datesSet">{{$t('resultsPage.dates')}}</span>
                    <span v-else>{{mid | mobileDate}} <span class="MRAIcon_arrow_dates"></span> {{mod | mobileDate}}</span>
                  </v-btn>
                  <v-card>
                    <DatePicker @saveDate="saveDate" @cancel="dateMenu2=false" :mid="mid" :mod="mod"></DatePicker>
                  </v-card>
                </v-menu>
              </v-flex>
          </v-layout>
          <!-- Budget mobile -->
          <v-layout  wrap class="hidden-md-and-up">
            <v-flex xs12 class="title">
                {{$t('resultsPage.budget')}}: {{price}}TWD
          </v-flex>
          <v-flex xs12>

            <v-slider class="pa-2" :hint="$t('resultsPage.budgetHint')" @end="sendPrice" v-model="price" :min="min" :max="max" :step="500" thumb-color="primary" thumb-label thumb-size="40" prepend-icon="attach_money">
            </v-slider>
          </v-flex>

          </v-layout>
          <!-- Bedtype mobile -->
          <v-layout  wrap v-if="view=='rooms'">
            <v-flex xs12 class="title">
                {{$t('features.bedType')}}
            </v-flex>
            <v-flex xs12>
              <v-select
                :items="bedTypes"
                v-model="bedType"

              >
              </v-select>
            </v-flex>
          </v-layout>


          <!-- property type mobile -->
          <v-layout  wrap v-if="view=='rooms'">
            <v-flex xs12 class="title">
                {{$t('resultsPage.propertyType')}}
            </v-flex>
            <v-flex xs12>
              <v-select
              :items="propTypes"
              v-model="propType"
            >
          </v-select>
            </v-flex>
          </v-layout>
          <!-- Contract Lenght -->
          <v-layout  wrap>
            <v-flex xs12 class="title">
                {{$t('pricing.contractLength')}}
            </v-flex>
            <v-flex xs12>
              <v-select
                :items="cLengthOptions"
                v-model="cLength"
              >
              </v-select>
            </v-flex>
          </v-layout>

          <!-- Number of bedrooms Mobile -->
          <v-layout  wrap v-if="view=='apts'">
            <v-flex xs12 class="title">
                {{$t('resultsPage.sharedOptions')}}
            </v-flex>
            <v-flex xs12>
              <v-select
              :items="aptTypeOptions"
              v-model="aptType"
              >
              </v-select>
            </v-flex>
          </v-layout>
          <v-layout  wrap v-if="view=='apts'">
            <v-flex xs12 class='title'>
              {{$t('resultsPage.numberOfRooms')}}
            </v-flex>
            <v-select
              :items="norOptions"
              v-model="nor"
            ></v-select>
          </v-layout>
          <v-layout wrap py-2>
            <v-flex xs12 class="title">
              Apartment Features

            </v-flex>
            <v-flex xs6 v-for="ft in apartmentFeatures" :key="ft.name">
              <v-checkbox  v-model="ft.value" hide-details>
                <template slot="label">
                  <span><span :class="ft.icon"></span>  {{ft.name}}</span>
                </template>
              </v-checkbox>

            </v-flex>
          </v-layout>
          <v-layout wrap class="py-2" v-if="view=='rooms'">
            <v-flex xs12  class="title">
              Room Features

            </v-flex>
            <v-flex xs6  v-for="ft in roomFeatures"  :key="ft.name">
              <v-checkbox return-object v-model="ft.value" hide-details>
                <template slot="label">
                  <span><span :class="ft.icon"></span>  {{ft.name}}</span>
                </template>
              </v-checkbox>

            </v-flex>
          </v-layout>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="removeAllFilters">{{$t('datePicker.clear')}}</v-btn>
            <v-btn color="secondary" flat @click="showAllFilters=false">{{$t('datePicker.cancel')}}</v-btn>
            <v-btn class="primary" @click="setAllFilters()">{{$t('datePicker.confirm')}}</v-btn>
          </v-card-actions>
        </v-card>
        </v-dialog>
      </div>
  <!--
        <v-select
        :items="
        Options"
        v-model="orderBy"
        return-object
        item-text="text"
        item-value="value"
        >
        </v-select> -->


        <v-menu :nudge-width="100" ref="orderMenu" class="hidden-sm-and-down" v-model="orderMenu">
          <v-btn color="primary" flat dark slot="activator">
            <span>{{$t('resultsPage.orderBy')}}</span>
            <v-icon>arrow_drop_down</v-icon>
          </v-btn>
          <v-list>
            <v-list-tile v-for="(item, index) in orderOptions" :key="index" @click="orderBy=item.value">
              <v-list-tile-title>{{item.text}}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>

    <!-- VIEW CHANGER -->
        <v-btn color="primary" flat dark :to="{path:baseUrl, query}">
          <span v-if="view=='apts'">{{$t('resultsPage.seeRooms')}}</span>
          <span v-else>{{$t('resultsPage.seeApartments')}}</span>
          <v-icon v-if="view=='apts'">meeting_room</v-icon>
          <v-icon v-else>domain</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-flex align-center class="hidden-xs-only" sm4>
          <!-- <v-layout align-center justify-space-between  class="search-bar">
              <v-flex class="flex-0">
                <v-icon color="primary">search</v-icon>
              </v-flex>
              <v-flex>
                <GmapAutocomplete @place_changed="setPlace" :placeholder="locName">
                </GmapAutocomplete>
              </v-flex>
          </v-layout> -->
            <v-autocomplete color="primary" light width="100%" :loading="loading" class="" @change="setPlace" hide-details :auto-select-first="true" v-model="search" :filter="searchFilter" :items="loc" item-value="name" prepend-icon="search" return-object clearable :placeholder="$t('homepage.enterLocation')">
              <template slot="no-data">
                <v-list-tile>
                  <v-list-tile-title>
                    Nothing result found
                  </v-list-tile-title>
                </v-list-tile>
              </template>
              <template slot="selection" slot-scope="{item, selected}">
                <v-icon>{{item.icon}}</v-icon>
                <span class="pl-2">{{item.name}}</span>
              </template>
              <template
                slot="item"
                slot-scope="{ item, tile }"
              >
                <v-icon>{{item.icon}}</v-icon>
                <span class="pl-2">{{item.name}}</span>
              </template>
            </v-autocomplete>
        </v-flex>
      </v-layout>
    </v-toolbar>
</div>
</template>

<script>
import moment from 'moment'
import DatePicker from '../shared/DatePicker.vue'
import firebase from '../../FirebaseApp'

export default {
  props: ['filters','min','max', 'view'],
  data: () => ({
    dateMenu:false,
    dateMenu2:false,
    priceMenu:false,
    showAllFilters:false,
    orderMenu:false,
    query:{},
    tempBedType:"",
    tempPropType:"",
    tempCLength:"",
    tempNor:"",
    tempAptType:"",
    apartmentFeatures: {
      kitchen:{
        value: false,
        icon: 'MRAIcon_kitchen',
        name: 'Kitchen'
      },
      wifi: {
        value: false,
        icon: 'MRAIcon_wifi',
        name: 'Wifi'
      },

      washingMachine: {
        value: false,
        icon: 'MRAIcon_laundry',
        name: 'Washing Machine'
      },

      tv: {
        value: false,
        icon: 'MRAIcon_tv',
        name: 'TV'
      },
      couch: {
        value: false,
        icon: 'MRAIcon_couch',
        name: 'Couch'
      },
      coffeeTable: {
        value: false,
        icon: 'MRAIcon_coffee_table',
        name: 'Coffee Table'
      },
      balcony: {
        value: false,
        icon: 'MRAIcon_balcony',
        name: 'Balcony'
      },
      rooftop: {
        value: false,
        icon: 'MRAIcon_rooftop',
        name: 'Rooftop'
      },
      elevator: {
        value: false,
        icon: 'MRAIcon_elevator',
        name: 'Elevator'
      },
      security: {
        value: false,
        icon: 'MRAIcon_security',
        name: 'Security'
      },
      cleaningService: {
        value: false,
        icon: 'MRAIcon_cleaning',
        name: 'Cleaning Service'
      },
      trashService: {
        value: false,
        icon: 'MRAIcon_trash_service',
        name: 'Trash Service'
      },
      noPets: {
        value: false,
        icon: 'MRAIcon_pets',
        name: 'Pets Allowed'
      },
      girlsOnly: {
        value: false,
        icon: 'MRAIcon_girlsOnly',
        name: 'Girls Only'
      },
      noSmoking:{
        value: false,
        icon: 'MRAIcon_smoking',
        name: 'Smoking Allowed'
      },
      utilitiesIncluded:{
        value:false,
        icon:"MRAIcon_utilities",
        name:"Utilities Included"
      },
    },
    roomFeatures: {
      ac: {
        value: false,
        icon: 'MRAIcon_air_conditioning',
        name: 'A/C'
      },
      fan: {
        value: false,
        icon: 'MRAIcon_fan',
        name: 'Fan'
      },
      outsideWindow: {
        value: false,
        icon: 'MRAIcon_outside_window',
        name: 'Outside Window'
      },

      curtains: {
        value: false,
        icon: 'MRAIcon_curtains',
        name: 'Curtains'
      },
      innerWindow: {
        value: false,
        icon: 'MRAIcon_inner_window',
        name: 'Inner Window'
      },
      mattress: {
        value: false,
        icon: 'MRAIcon_mattress',
        name: 'Mattress'
      },
      pillow: {
        value: false,
        icon: 'MRAIcon_pillow',
        name: 'Pillow'
      },
      sheets: {
        value: false,
        icon: 'MRAIcon_sheets',
        name: 'Bed sheets'
      },
      desk: {
        value: false,
        icon: 'MRAIcon_desk',
        name: 'Desk'
      },
      chair: {
        value: false,
        icon: 'MRAIcon_chair',
        name: 'Chair'
      },
      wardrobe: {
        value: false,
        icon: 'MRAIcon_wardrobe',
        name: 'Wardrobe'
      },
      openHanger: {
        value: false,
        icon: 'MRAIcon_open_hanger',
        name: 'Open Hanger'
      },
      shelve: {
        value: false,
        icon: 'MRAIcon_shelves',
        name: 'Shelve'
      },
      couplesAllowed:{
        value: false,
        icon: 'MRAIcon_couples2',
        name: 'Couples Allowed'
      },
    },

    loading:true,
  }),
  firebase() {

    return {

      loc:{
        source: firebase.database().ref('locations'),
        readyCallback: function(){
          this.loading=false
        }
      }

    }

  },

  computed:{
    baseUrl(){
      console.log(this.view);
      let url=""
      if (this.view=="apts"){
        return '/search'
      } else {
        return '/aptSearch'
      }
    },
    orderOptions:function(){
      let t=[
        {
          text:'Recommended',
          value:'MRA'
        },

        { text:'Price (Low to High)',
          value:'priceLtH'
        },
        {
          text:"Price (High to Low)",
          value:'priceHtL'
        },
        {
          text:"Distance",
          value:"distance",
        },
        {
          text:"Bicycle Time",
          value:'time',
        },
        {
          text: 'Closest to move in date',
          value: 'closestToMoveIn',
        },
        {
          text:'Newest first',
          value:'newest',
        },
        {
          text:'Oldest first',
          value:'oldest'
        }
      ]
      if (this!=undefined){
        t=[
          {
            text:'Recommended',
            value:'MRA'
          },
          {
            text:this.$t('resultsPage.orderByOptions.priceLtH'),
            value:'priceLtH'
          },
          {
            text:this.$t('resultsPage.orderByOptions.priceHtL'),
            value:'priceHtL'
          },
          {
            text:this.$t('resultsPage.orderByOptions.distance'),
            value:"distance",
          },
          {
            text:this.$t('resultsPage.orderByOptions.closestToMid'),
            value: 'closestToMoveIn',
          },
          {
            text:this.$t('resultsPage.orderByOptions.newest'),
            value:'newest',
          },
          {
            text:this.$t('resultsPage.orderByOptions.oldest'),
            value:'oldest'
          }

        ]
      }
      return t
    },
    bedTypes:function(){
      let t=['Any','None', 'Single', 'Double']
      if (this!=undefined){
        t=[{
            text: this.$t('resultsPage.any'),
            value: ''
          },
          {
            text: this.$t("features.bedTypeOptionNone"),
            value: 'None'
          },
          {
            text: this.$t("features.bedTypeOptionSingle"),
            value: 'Single'
          },
          {
            text: this.$t("features.bedTypeOptionDouble"),
            value: 'Double'
          },
        ]
        return t
      }
    },
    propTypes: function(){
      let t=[{
          text: 'Any',
          value: ''
        },
        {
          text: 'Room in shared flat',
          value: 'room'
        },
        {
          text: 'Studio',
          value: 'studio'
        },
        {
          text: 'Private Bathroom',
          value: 'privateBath'
        },
      ]
      if (this!= undefined){
        t=[{
            text: this.$t("resultsPage.any"),
            value: ''
          },
          {
            text: this.$t("resultsPage.propertyTypes.room"),
            value: 'room'
          },
          {
            text: this.$t("resultsPage.propertyTypes.studio"),
            value: 'studio'
          },
          {
            text: this.$t("features.privateBathroom"),
            value: 'privateBath'
          },

        ]
      }
      return t
    },
    norOptions(){
      let t=[
      {
        text:'1',
        value:1,
      },{
        text:'2',
        value:2,
      },
      {
        text:'3',
        value:3,
      },{
        text:'4',
        value:4
      },{
        text: this.$t("homepage.moreThan")+' 4',
        value:5
      },]
      return t
    },
    aptTypeOptions(){
      let t=[
        {
          text:"Any",
          value:''
        },
        {
          text:"Whole flat",
          value:'wholeApt'
        },
        {
          text: "Shared flat",
          value:'roomApt'
        },
      ]
      if (this!=undefined){
        t=[
          {
            text:this.$t("resultsPage.any"),
            value:''
          },
          {
            text:this.$t("newListing.wholeFlat"),
            value:'wholeApt'
          },
          {
            text: this.$t("resultsPage.sharedFlat"),
            value:'roomApt'
          },
        ]
      }
      return t
    },
    cLengthOptions(){
      let t=[
         {
           text:"Any",
           value:''
         },
         {
           text:"1 Month",
           value:1
         },
         {
           text:"3 Months",
           value:2
         },
         {
           text:"5 Months",
           value:3
         },
       ]
      if (this!=undefined){
       t=[
          {
            text:this.$t("resultsPage.any"),
            value:''
          },
          {
            text:this.$t("contractDetails.minLengthOptions.one"),
            value:1
          },
          {
            text:this.$t("contractDetails.minLengthOptions.three"),
            value:2
          },
          {
            text:this.$t("contractDetails.minLengthOptions.five"),
            value:3
          },
        ]
      }
      return t
    },

    datesSet(){
      if (this.filters.mid=="" || this.filters.mid==undefined){
        if (this.filters.mod=="" || this.filters.mod==undefined){
          return false
        }
      }
      return true
    },
    priceSet(){
      if (this.filters.price=="" || this.filters.price==undefined || this.filters.price==this.max){
        return false
      } else {
        return true
      }
    },
    othersSet(){
      // console.log('others:',this.checkBedType(),this.checkPropType() , this.checkAptType() , this.checkNor(), this.checkcLength());
      if (this.checkBedType()||this.checkPropType() || this.checkAptType() || this.checkNor()|| this.checkcLength()){
        return true
      } else {
        return false
      }
    },
    aptTypeSet(){
      if (this.aptType==""|| this.aptType==undefined){
        return false
      } else {
        return true
      }
    },
    search:{
      get(){
        this.query.name=this.filters.locName
        this.query.lat=this.filters.lat
        this.query.lng=this.filters.lng
        if (this.filters.locName!=""&& this.filters.locName!=undefined && this.loc!=undefined){
          let t=this.loc.find(x=>{return x.name==this.filters.locName})

          return t
        } else {
          return null
        }
      },
      set(item){
        // this.query.name=this.filters.locName
        console.log("SET SEARCH");
      }
    },
    mid:{
      get(){
        if (this.filters.mid!=""&& this.filters.mid!=undefined && moment(this.filters.mid).isValid()){
          console.log('valid mid');
          this.query.mid=moment(this.filters.mid).format("YYYY-MM-DD")
          return moment(this.filters.mid).format("YYYY-MM-DD")
        } else {
          this.query.mid=this.filters.mid
          return this.filters.mid
        }
      },
      set(val){
        if (val!="" && val!=undefined && moment(val).isValid()){
          this.query.mid=moment(val).format("YYYY-MM-DD")
        } else {
          this.query.mid=""
        }
        let query=this.query
        this.$router.push({query})
      }
    },
    mod:{
      get(){
        if (this.filters.mod!=""&& this.filters.mod!=undefined){
          this.query.mod=moment(this.filters.mod).format("YYYY-MM-DD")
          return moment(this.filters.mod).format("YYYY-MM-DD")
        } else {
          this.query.mod=this.filters.mod
          return this.filters.mod
        }
      },
      set(val){
        if (val!="" && val!=undefined && moment(val).isValid()){
          this.query.mod=moment(val).format("YYYY-MM-DD")
        } else {
          this.query.mod=""
        }
        let query=this.query
        this.$router.push({query})
      }
    },
    bedType:{
      get(){
        this.query.bedType=this.filters.bedType
        return this.filters.bedType
      },
      set(val){
        this.tempBedType=val
        // console.log('setBed',val);
      }
    },
    propType:{
      get(){
        this.query.propType=this.filters.propType
        return this.filters.propType
      },
      set(val){
        this.tempPropType=val
        // this.query.propType=val
        // let query=this.query
        // this.$router.push({query})
      }
    },
    cLength:{
      get(){
        this.query.cLength=this.filters.cLength
        return this.filters.cLength
      },
      set(val){
        this.tempCLength=val
      }
    },

    nor:{
      get(){
        this.query.nor=this.filters.nor
        return this.filters.nor
      },
      set(val){
        this.tempNor=val
      }
    },
    aptType:{
      get(){
        this.query.aptType=this.filters.aptType
        return this.filters.aptType
      },
      set(val){
        this.tempAptType=val
      }
    },

    price:{
      get(){
        // console.log('the price is:',this.filters.price);
        this.query.price=this.filters.price
        if (this.filters.price==undefined||this.filters.price==""){
          return this.max
        } else {
          return this.filters.price
        }
      },
      set(val){
        // console.log('setPrice');
      }
    },
    orderBy:{
      get(){
        this.query.orderBy=this.filters.orderBy
        if (this.filters.orderBy=="" || this.filters.orderBy==undefined){
          return "MRA"
        } else {
          return this.filters.orderBy
        }
      },
      set(val){
        this.query.orderBy=val
        let query=this.query
        this.$router.push({query})
      }
    },

  },
  methods: {
    searchFilter(item, query){
      return item.name.toLowerCase().includes(query.toLowerCase())
    },
    resetFts(){
      for (var el in this.roomFeatures) {
        if (this.roomFeatures.hasOwnProperty(el)) {
          this.roomFeatures[el].value=false
        }
      }
      for (var el in this.apartmentFeatures) {
        if (this.apartmentFeatures.hasOwnProperty(el)) {
          this.apartmentFeatures[el].value= false
        }
      }
    },
    setFts(){
      let t=[]
      for (var el in this.roomFeatures) {
        if (this.roomFeatures.hasOwnProperty(el)) {
          if (this.roomFeatures[el].value==true){
            console.log(el);
            t.push(el)
          }
        }
      }
      for (var el in this.apartmentFeatures) {
        if (this.apartmentFeatures.hasOwnProperty(el)) {
          if (this.apartmentFeatures[el].value==true){
            console.log(el);
            t.push(el)
          }
        }
      }
      this.fts=t
    },
    setAllFilters(){
      if (this.view=='apts'){
        this.query.nor=this.tempNor
        this.query.aptType=this.tempAptType
      } else {
        this.query.bedType=this.tempBedType
        this.query.propType=this.tempPropType
      }
      this.query.cLength=this.tempCLength
      this.setFts()
      this.query.fts=JSON.stringify(this.fts)
      this.showAllFilters=false
      console.log(this.query);
      let query=this.query
      this.$router.push({query})

    },
    sendPrice(val){
      console.log(val);
      this.priceMenu=false
      this.query.price=val
      let query=this.query
      this.$router.push({query})
    },
    setPlace(place) {
      console.log('New Place: ',place);
      // place.lat = place.geometry.location.lat()
      // place.lng = place.geometry.location.lng()
      // // console.log('place')
      // // console.log(place)
      if (place!=undefined){
        this.query.lat = place.lat
        this.query.lng = place.lng
        this.query.name = place.name
      } else if (this.filters.hasOwnProperty('lat')){
        this.query.lat = this.filters.lat
        this.query.lng = this.filters.lng
        this.query.name = this.filters.name
      }
      // this.query.id = place.id
      this.query.orderBy= "MRA"
      let query = this.query
      this.$router.push({
        query
      })
    },
    changeView(){
      let query=this.query
      if (this.view=='apts'){
        this.$router.push({name:'roomSearch',query:query})
      } else {
        this.$router.push({name:'aptSearch',query:query})
      }
    },
    removeAllFilters(){
        this.mid=""
        this.mod=""
        this.propType=""
        this.bedType=""
        this.aptType=""
        this.nor=""
        this.price=""
        this.orderBy=""
        this.cLength=""
        this.resetFts()
    },
    checkMid(){
      if (this.filters.mid!=""&&this.filters.mid!=undefined){
        return true
      } else {return false}

    },
    checkMod(){
      if (this.filters.mod!="" && this.filters.mod!=undefined){
        return true
      } else {return false}
    },
    checkBedType(){
      if (this.filters.bedType!=""&& this.filters.bedType!=undefined){
        return true
      } else {return false}
    },
    checkPropType(){
      if (this.filters.propType!=""&& this.filters.propType!=undefined){
        return true
      } else {return false}
    },

    checkAptType(){
      if (this.filters.aptType!=""&& this.filters.aptType!=undefined){
        return true
      } else {return false}
    },
    checkNor(){
      if (this.filters.nor!=""&& this.filters.nor!=undefined){
        return true
      } else {return false}
    },
    checkcLength(){
      if (this.filters.cLength!=""&& this.filters.cLength!=undefined){
        return true
      } else {return false}
    },
    saveDate(dates) {
      // let query=this.
      // console.log(dates)
      console.log(dates[0],dates[1]);
      this.dateMenu = false
      this.dateMenu2=false
      this.mid=dates[0]
      this.mod=dates[1]
    }
  },
  filters:{
    displayDate(t){
      if (t!="" && t!=undefined){

        return moment(t).format("MMM")
      } else {
        return ""
      }
    },
    mobileDate(t){
      if (t!="" && t!=undefined){
        return moment(t).format("DD MMM")
      } else {
        return ""
      }
    }
  },
  created() {
    //do something after creating vue instance
    // console.log('route in searchbar', this.$route.query);
    if (this.$route.hasOwnProperty('query')&& this.$route.query.hasOwnProperty('fts')){
      let features=JSON.parse(this.$route.query.fts)
      // console.log(features);
      for (var i = 0; i < features.length; i++) {
        if (this.roomFeatures.hasOwnProperty(features[i])){
          this.roomFeatures[features[i]].value=true
        } else if(this.apartmentFeatures.hasOwnProperty(features[i])){
          this.apartmentFeatures[features[i]].value=true
        } else {
          console.log("Cant find ", features[i]);
        }
      }
    }
  },
  components:{
    DatePicker,
  }
}
</script>

<style lang="scss" scoped>
.colored{
  color:#00Bfff!important;
}
</style>
