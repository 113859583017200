<template>
<div class="">

  <v-date-picker color="primary" class="range-picker" v-model="datesAr" :locale="$vuetify.lang.current" :events="selectedDatesAr" event-color="rgba(0,191,255,.15)" :close-on-content-click="false" multiple no-title :min="minDate" @input="checkDates">
    <v-spacer></v-spacer>

    <v-btn v-if="!noButtons" flat color="primary" @click="clear">{{$t('datePicker.clear')}}</v-btn>
    <v-btn flat  v-if="!noButtons" color="primary" @click="cancel">{{$t('datePicker.cancel')}}</v-btn>
    <v-btn flat  v-if="!noButtons" color="primary" @click="confirm">{{$t('datePicker.confirm')}}</v-btn>
  </v-date-picker>
</div>
</template>

<script>
import moment from 'moment'
export default {
  props: ['mid', 'mod','minDate', 'noButtons'],
  data: () => ({
    datesAr: [],
    finalDatesAr: [],
    selectedDatesAr: [],
  }),
  methods: {
    checkDates(newDateArr) {
      let newDate = newDateArr.slice(-1)[0]
      let dateNewDate = new Date(newDate)
      // console.log(this.datesAr.length)
      // console.log('New Date: ', newDate)
      switch (this.finalDatesAr.length) {
        case 0:
          this.finalDatesAr.push(newDate)
          // console.log(this.finalDatesAr)
          this.datesAr = this.finalDatesAr
          if(this.noButtons){
              this.confirm()
          }
          break;
        case 1:
          if (new Date(this.finalDatesAr[0]).getTime() < dateNewDate.getTime()) {
            this.finalDatesAr.push(newDate)
          } else {
            this.finalDatesAr.splice(0, 0, newDate)
          }
          this.datesAr = this.finalDatesAr
          this.getDatesBetween()
          if(this.noButtons){
              this.confirm()
          }
          break
        default:
        case 2:
          if (new Date(this.finalDatesAr[0]).getTime() > dateNewDate.getTime()) {
            this.finalDatesAr.splice(0, 1, newDate)
          } else if (new Date(this.finalDatesAr[1]).getTime() < dateNewDate.getTime()) {
            this.finalDatesAr.splice(1, 1, newDate)
          } else {
            this.compareDates(newDate)
          }
          this.datesAr = this.finalDatesAr
          this.getDatesBetween()
          if(this.noButtons){
              this.confirm()
          }
          break
      }
    },
    compareDates(newDate) {
      // console.log('Comparing dates')
      let diffDateOne = Math.abs((new Date(this.finalDatesAr[0]).getTime() - new Date(newDate).getTime()))
      let diffDateTwo = Math.abs((new Date(this.finalDatesAr[1]).getTime() - new Date(newDate).getTime()))
      if (diffDateOne < diffDateTwo) {
        this.finalDatesAr.splice(0, 1, newDate)
      } else {
        this.finalDatesAr.splice(1, 1, newDate)
      }
      this.datesAr = this.finalDatesAr
      this.getDatesBetween()
    },
    getDatesBetween() {
      this.selectedDatesAr = []
      let startDate = moment(this.finalDatesAr[0])
      let lastDate = moment(this.finalDatesAr[1]).startOf('day')
      let currDate = startDate.startOf('day')
      // console.log('lastDate: ', lastDate, 'currDate: ', currDate)
      while (currDate.add(1, 'days').diff(lastDate) < 0) {
        // console.log(currDate.format('YYYY-MM-DD'));
        this.selectedDatesAr.push(currDate.format('YYYY-MM-DD'));
      }


    },

    confirm() {
      this.$emit('saveDate', this.finalDatesAr)
    },
    clear() {
      this.selectedDatesAr = []
      this.datesAr = []
      this.finalDatesAr = []
      this.confirm()
    },
    cancel() {
      if (this.mid != undefined && this.mid !="") {
        if (moment(this.mid).isValid()){
          this.datesAr[0] = moment(this.mid).format('YYYY-MM-DD')
          this.finalDatesAr = this.datesAr
        } else {
          this.datesAr[0] = moment(parseFloat(this.mid)).format('YYYY-MM-DD')
          this.finalDatesAr = this.datesAr
        }
        if (this.mod != undefined && this.mid !="") {
          if (moment(this.mod).isValid()){
            this.datesAr[1] = moment(this.mod).format('YYYY-MM-DD')
            this.finalDatesAr = this.datesAr
            this.getDatesBetween()
          } else {
            this.datesAr[1] = moment(parseFloat(this.mod)).format('YYYY-MM-DD')
            this.finalDatesAr = this.datesAr
            this.getDatesBetween()

          }
        }
      }
      this.$emit('cancel')
    }
  },
  created() {
    if (this.mid != undefined && this.mid != "") {
      // console.log('the parsefloat of mid:', isNaN(parseFloat(this.mid)))
      if (moment(this.mid).isValid()){
        this.datesAr[0] = moment(this.mid).format('YYYY-MM-DD')
        this.finalDatesAr = this.datesAr
      } else {
        if (!isNaN(parseFloat(this.mid))){
          // console.log('this.MID: ',this.mid,'parseFloat of mid:', parseFloat(this.mid))
          this.datesAr[0] = moment(parseFloat(this.mid)).format('YYYY-MM-DD')
          this.finalDatesAr = this.datesAr
        } else {
          this.datesAr[0] = moment(this.mid).format('YYYY-MM-DD')
          this.finalDatesAr = this.datesAr
        }
      }
      if (moment(this.mod).isValid()) {
        this.datesAr[1] = moment(this.mod).format('YYYY-MM-DD')
        this.finalDatesAr = this.datesAr
        this.getDatesBetween()
      } else {
        if (this.mod != undefined && this.mod != "") {
          if (!isNaN(parseFloat(this.mod))){
            this.datesAr[1] = moment(parseFloat(this.mod)).format('YYYY-MM-DD')
            this.finalDatesAr = this.datesAr
            this.getDatesBetween()
          } else {
            this.datesAr[1] = moment(this.mod).format('YYYY-MM-DD')
            this.finalDatesAr = this.datesAr
            this.getDatesBetween()
          }
        }
      }
    }

  },
  computed: {
    locale(){
      switch (this.$vuetify.lang.current) {
        case 'zh':
          return 'zh-hant'
        default:
          return 'en'
      }
    },
  //   datesAr: {
  //     get: function() {
  //     let a=[]
  //     if (this.mid!=undefined){
  //       a[0]=moment(parseFloat(this.mid)).format('YYYY-MM-DD')
  //       if (this.mod!=undefined){
  //         a[1]=moment(parseFloat(this.mod)).format('YYYY-MM-DD')
  //         this.finalDatesAr=a
  //         this.getDatesBetween()
  //       }
  //     }
  //     return a
  //   },
  //   set: function(val){
  //     this.$emit('updateFilters',val)
  //   }
  // }
  },

}
</script>

<style lang="scss" scoped>
</style>
