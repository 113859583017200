import { render, staticRenderFns } from "./SearchBarRooms.vue?vue&type=template&id=04c04b70&scoped=true&lang=html&"
import script from "./SearchBarRooms.vue?vue&type=script&lang=js&"
export * from "./SearchBarRooms.vue?vue&type=script&lang=js&"
import style0 from "./SearchBarRooms.vue?vue&type=style&index=0&id=04c04b70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c04b70",
  null
  
)

export default component.exports